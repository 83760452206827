<template>
  <UiPill
    :prefix-component="{
      component: 'icon',
      name: 'heart-filled',
      colorFill: 'var(--color-base-red-100)',
    }"
    :text="$t('layout.header.getStarted')"
    class="body-m"
    type="secondary"
    url="/account/user-onboarding"
    @click="onGetStartedClick"
  />
</template>

<script lang="ts" setup>
import UiPill from '@sephora-asia/ui-pill'

const { trackEvent } = useGtmTrack()

function onGetStartedClick() {
  trackEvent({
    event: 'sign_up_click_get_started',
  })
}
</script>
