<template>
  <div class="sidebar-item-container">
    <UiLabel
      :icons="{
        left: {
          name: 'heart-filled',
          colorFill: 'var(--color-base-red-100)',
        },
      }"
      :text="$t('layout.header.getStarted')"
      class="icon-label sidebar-item body-m"
      url="/account/user-onboarding"
      @click="onGetStartedClick"
    />
  </div>
</template>

<script lang="ts" setup>
import UiLabel from '@sephora-asia/ui-label'

const { trackEvent } = useGtmTrack()

function onGetStartedClick() {
  trackEvent({
    event: 'sign_up_click_get_started',
  })
}
</script>

<style lang="scss" scoped>
.icon-label {
  :deep(.icon-container) {
    margin-right: 12px;
  }
}

.sidebar-item-container {
  .sidebar-item,
  :deep(.accordion-header) {
    padding: 12px 20px;
  }
}
</style>
